<template>
  <div id="orders" class="grid">
    <div class="pg-header">
      <h2>Dagsrapporter</h2>
      <p class="pg-header-label-text">30 senaste</p>
    </div>
    <div id="alertBox" role="alert">
      <transition-group name="fade">
        <div v-show="showSuccessAlertBox" key="ok" class="alert alert-success">
          <strong>{{ message }}</strong>
        </div>
        <div v-show="showDangerAlertBox" key="nok" class="alert alert-danger">
          <strong>{{ message }}</strong>
        </div>
      </transition-group>
    </div>
    <div class="pg-table">
      <div v-if="errorMessage === '' && !loading">
        <div class="pg-table-toolbar">
          <div class="pg-search-input">
            <input v-model="search" type="text" class="searchInput" placeholder="Sök" />
          </div>
          <div class="pg-table-toolbar-action-buttons">
            <div v-if="isWorker || isSeller || isAdmin">
              <button class="btn-primary" onmousedown="event.preventDefault()" @click="reload">Uppdatera</button>
            </div>
            <div v-if="isWorker || isSeller || isAdmin">
              <button class="btn-primary" @click="addDayReport">Lägg till</button>
            </div>
          </div>
        </div>
        <div>
          <table id="tblDayReports" class="content-table">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Montör/Säljare</th>
                <th style="max-width: 150px">Delmonterade</th>
                <th style="max-width: 150px">Slutmonterade</th>
                <th style="max-width: 150px">Slutmonterade ellås</th>
                <th style="max-width: 150px">Sålda boxar</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="!loading && dayReports.length > 0">
              <tr v-for="dayreport in filtredDayReports" :key="dayreport.day_report_id">
                <td>{{ dayreport.day_report_date }}</td>
                <td>{{ dayreport.user_full_name }}</td>
                <td>{{ dayreport.partially_assembled_amount }}</td>
                <td>{{ dayreport.final_assembled_amount }}</td>
                <td>{{ dayreport.final_assembled_electric_locks_amount }}</td>
                <td>{{ dayreport.sold_boxes_amount }}</td>
                <td @click="clickComment(dayreport.day_report_id)">
                  <img
                    src="../../assets/comment-regular.svg"
                    class="svg-icon"
                    :class="{
                      'svg-icon-available': dayreport.comments.length > 0,
                      'svg-icon-not-available': dayreport.comments.length < 1
                    }"
                    title="Kommentera rapport" />
                </td>
                <td @click="editDayReport(dayreport.day_report_id)">
                  <img src="../../assets/edit-regular.svg" class="svg-icon svg-icon-edit" title="Editera rapport" />
                  <!-- <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="edit"
                      class="svg-icon svg-icon-edit"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                      ></path>
                    </svg>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
      <div v-if="errorMessage !== ''" class="pg-center">
        <p class="errorMessageText">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/utils/Spinner';

export default {
  name: 'DayReports',
  components: {
    Spinner
  },
  props: {
    addUpdateOK: {
      type: Boolean,
      default: false
    },
    hasAddUpdate: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      dayReports: [],
      myDayReport: {
        day_report_date: new Date(),
        user_id: 0,
        user_full_name: '',
        partially_assembled_amount: 0,
        final_assembled_amount: 0,
        sold_boxes_amount: 0,
        comments: ''
      },
      search: '',
      message: '',
      showSuccessAlertBox: false,
      showDangerAlertBox: false
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    },
    filtredDayReports() {
      const trimmedSearch = this.search.toLowerCase().trim();
      return this.dayReports.filter((dayreport) => {
        return (
          dayreport.day_report_date.toLowerCase().match(trimmedSearch) ||
          dayreport.user_full_name.toLowerCase().match(trimmedSearch) ||
          dayreport.partially_assembled_amount.toLocaleString().toLowerCase().match(trimmedSearch) ||
          dayreport.final_assembled_amount.toLocaleString().toLowerCase().match(trimmedSearch) ||
          dayreport.final_assembled_electric_locks_amount.toLocaleString().toLowerCase().match(trimmedSearch) ||
          dayreport.sold_boxes_amount.toLocaleString().toLowerCase().match(trimmedSearch)
        );
      });
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchMyDayReports();
    this.loading = false;
  },
  created() {
    if (this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Dagsrapporten är nu sparad';
      }
      if (this.action == 'DELETE') {
        this.message = 'Dagsrapporten är nu raderad';
      }
      setTimeout(() => (this.showSuccessAlertBox = true), 0);
      setTimeout(() => (this.showSuccessAlertBox = false), 3000);
    }
    if (!this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Dagsrapporten kunde inte sparas';
      }
      if (this.action == 'DELETE') {
        this.message = 'Dagsrapporten kunde inte raderas';
      }
      setTimeout(() => (this.showDangerAlertBox = true), 0);
      setTimeout(() => (this.showDangerAlertBox = false), 3000);
    }
  },
  updated() {},
  beforeUpdate() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      fetchDayReports: 'reports/fetchDayReports'
    }),
    clickComment: function (day_report_id) {
      this.$router.replace({
        name: 'DayReportComments',
        params: {
          dayReportId: day_report_id
        }
      });
    },
    editDayReport: function (day_report_id) {
      this.$router.replace({
        name: 'DayReport',
        params: {
          dayReportId: day_report_id,
          originView: 'DayReports'
        }
      });
    },
    addDayReport() {
      this.$router.replace({
        name: 'DayReport',
        params: {
          orderId: 0,
          originView: 'DayReports'
        }
      });
    },

    async reload() {
      await this.fetchMyDayReports();
    },
    async fetchMyDayReports() {
      this.errorMessage = '';
      try {
        this.dayReports = [];
        this.dayReports = await this.fetchDayReports();
      } catch (error) {
        // console.log("fetchMyOrders ERROR");
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pg-table {
  grid-column: 3/11;
}
#alertBox {
  grid-column: 3/11;
}
@media (max-width: 1690px) {
  .pg-table {
    grid-column: 2/12;
  }
  #alertBox {
    grid-column: 2/12;
  }
}
@media (max-width: 1270px) {
  .pg-table {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  #alertBox {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
